export const BG_COVER = `https://ik.imagekit.io/masansgaming/Jonathan%20Nathania/Cover_jQJfZ6Goz.jpg?updatedAt=1695774855339`;
export const BG_COUNTING = `https://ik.imagekit.io/masansgaming/Jonathan%20Nathania/counting%20days%202_1VVI9KI7c.jpg?updatedAt=1695774852809`;
export const BG_FOOTER = `https://ik.imagekit.io/masansgaming/Jonathan%20Nathania/Footer%20Portrait%201~_k2uGnxcUK.jpg?updatedAt=1695774851101`;
export const BG_LIVESTREAM = ``;
export const BG_LOVESTORY = `https://ik.imagekit.io/masansgaming/Jonathan%20Nathania/love%20story%201_seWOj-z4Q.jpg?updatedAt=1695774848278`;
export const BG_GIFTCARD = ``;
export const BG_DESKTOP_VIEW = `https://ik.imagekit.io/masansgaming/Jonathan%20Nathania/Desktop%20view_r_ffv-DPt.jpg?updatedAt=1695774854999`;

export const IMG_LAMPION = `https://ik.imagekit.io/masansgaming/Jonathan%20Nathania/new%20lampion_eQecgy7A0.png?updatedAt=1696164583594`;
// images welcome section
export const IMG_WELCOME_1 = `https://ik.imagekit.io/masansgaming/Jonathan%20Nathania/welcomming%201_6OMs6YtZR.jpg?updatedAt=1695774849960`;
export const IMG_WELCOME_2 = `https://ik.imagekit.io/masansgaming/Jonathan%20Nathania/Welcoming%202_hxOR1Cv-p.jpg?updatedAt=1695774851552`;
export const IMG_WELCOME_3 = `https://ik.imagekit.io/masansgaming/Jonathan%20Nathania/Welcomingg%203_KYcABpjmN.jpg?updatedAt=1695774850495`;
export const IMG_WELCOME_4 = `https://ik.imagekit.io/masansgaming/Jonathan%20Nathania/welcomingg%204_myCvU9ga0.jpg?updatedAt=1695774851681`;
export const IMG_WELCOME_5 = `https://ik.imagekit.io/masansgaming/Jonathan%20Nathania/welcomingg%205_UI4KI_VC4.jpg?updatedAt=1695774850738`;
// couple image
export const IMG_MAN = `https://ik.imagekit.io/masansgaming/Jonathan%20Nathania/Groom%20Portrait_84YJ6aXXj.jpg?updatedAt=1695774849668`;
export const IMG_GIRL = `https://ik.imagekit.io/masansgaming/Jonathan%20Nathania/Bride%20Portrait_Ko05amFCN.jpg?updatedAt=1695774852274`;
export const IMG_PHOTO_COVER = `https://user-images.githubusercontent.com/10141928/150639548-4e4b612b-35c4-4fda-88d7-fa3cdd97295d.png`;
export const IMG_LOGO_WEDDING = `https://ik.imagekit.io/masansgaming/Jonathan%20Nathania/new%20castle_xGzTR60Ue.png?updatedAt=1696164770920`;
export const IMG_LOVE_STORY = `https://ik.imagekit.io/masansgaming/Jonathan%20Nathania/love%20story%201_seWOj-z4Q.jpg?updatedAt=1695774848278`;
export const IMG_LOGO = ``;

// video assets
export const VIDEO_COVER = `https://user-images.githubusercontent.com/10141928/150642624-d39b2ace-41e7-4464-a1ed-1cadc519503a.mp4`;
// image photo gallery cover
export const IMG_PHOTO_1 = `https://ik.imagekit.io/masansgaming/Jonathan%20Nathania/Cover%20Galeri%201_s5mcGYmrp.jpg?updatedAt=1695774853358`;
export const IMG_PHOTO_2 = `https://ik.imagekit.io/masansgaming/Jonathan%20Nathania/Cover%20Galeri%202_oO7FsF428.jpg?updatedAt=1695774854132`;
export const IMG_PHOTO_3 = `https://ik.imagekit.io/masansgaming/Jonathan%20Nathania/cover%20galeri%203_jHAt_q8G8.jpg?updatedAt=1695774854615`;
// image icons
export const IMG_ICON_HAND = `https://user-images.githubusercontent.com/10141928/150661877-d975b35b-3e55-4333-b216-2f9df6ca2584.png`;
export const SONG_CDN_URL = `https://res.cloudinary.com/invitato/video/upload/v1640358887/template-song/LeeHi_-_ONLY_Lyrics_nzuosa.mp4`;

export const IMG_COVID = {
  id: `https://user-images.githubusercontent.com/10141928/152914092-4dfb40e5-9552-49dc-a2d4-72a345f08b06.png`,
  en: `https://user-images.githubusercontent.com/10141928/158618733-55b6141e-dc5a-4a6e-a65e-953cb09127dc.jpg`,
};

export const LOGO_INVITATO = `https://user-images.githubusercontent.com/10141928/150662036-816aabf8-76f9-4342-bc47-3d7892362753.png`;

//Collaboration
export const LOGO_VENDOR_DALANG = `https://ik.imagekit.io/invitatoid/global-assets/logo-partner/partner-dalang_bp5JgCazz.png?ik-sdk-version=javascript-1.4.3&updatedAt=1651639908257`;
export const LOGO_VENDOR_PENTONE_WHITE = `https://ik.imagekit.io/invitatoid/global-assets/logo-partner/partner-pentone-white_4cMUJafwM.png?ik-sdk-version=javascript-1.4.3&updatedAt=1654518552744`;
export const LOGO_VENDOR_PENTONE_BLACK = 'https://user-images.githubusercontent.com/103095433/193169607-562d8316-835d-4c15-a6b4-2b3c7cd22621.png';
export const LOGO_VENDOR_TIRTOWENING = 'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-tirtowening_2o6jmuIlN.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708911787';
export const LOGO_VENDOR_SAMAWA = 'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-samawa_KniSfe28i.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708911529';
export const LOGO_VENDOR_SADEWA = 'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-sadewa_6J3D8PmsJ.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708911185';
export const LOGO_VENDOR_PRAHASTA = 'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-prahasta-white_5L4WQTBlc.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708910828';
export const LOGO_VENDOR_FLATTER = 'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-flatter_SvHO8RoVF.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708910005';
export const LOGO_VENDOR_AKSARA = 'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-aksara-white_Ofc6Xkib-.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708909659';
export const LOGO_VENDOR_DREAMWORKS = 'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-dreamworks-white_FFlUUi8Dk.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708910073';